<script setup>
import {useTelegramStore} from "@/store/telegram";
import {computed} from "vue";

const tgStore = useTelegramStore();
const userFirstName = computed(() => tgStore.user.first_name);
</script>

<template>
	<div class="RestaurantPageNewHeaderTopBar_topBarContainer RestaurantPageNewHeaderTopBar_floating" v-bind:class="getClass('scroll')" style="display: none;">

		<div class="RestaurantPageNewHeaderTopBar_whiteTB">
<!--			<div class="MobileHeaderContainer_iconWrapper MobileHeaderContainer_icon" @click="$emit('openNav')" v-if="homePage()">-->
<!--				<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileHeaderContainer_burgerIcon">-->
<!--					<path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v1Zm0 5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v1Zm.5 5.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-15Z" fill="currentColor"></path>-->
<!--				</svg>-->
<!--			</div>-->
			<div class="Clickable_root Clickable_rootWithAnimation RestaurantPageNewHeaderTopBar_headBtn" v-if="!homePage()" @click="$router.back()">
				<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root RestaurantPageNewHeaderTopBar_topbarIcon">
					<path fill-rule="evenodd" clip-rule="evenodd" d="m7.8 11 5.246-5.246a.5.5 0 0 0 0-.708l-.692-.692a.5.5 0 0 0-.708 0L4 12l7.646 7.646a.5.5 0 0 0 .708 0l.692-.692a.5.5 0 0 0 0-.708L7.8 13h11.7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H7.8Z" fill="currentColor"></path>
				</svg>
			</div>
			<div @click="$router.push('/')" class="RestaurantPageNewHeaderTopBar_headTitle RestaurantPageNewHeaderTopBar_hasFavoriteButton">У Беллуччи Круче</div>
<!-- 			<div class="FavoriteButton_root MobileFavoriteButton_buttonRoot RestaurantPageNewHeaderTopBar_headBtn">
				<div class="FavoriteIcon_root RestaurantPageNewHeaderTopBar_favBtnIcon FavoriteIcon_withBackground FavoriteIcon_stateOff">
					<div class="FavoriteIcon_icon">
						<svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11 18.0433L10.6154 17.8179C10.2218 17.5925 1 12.1779 1 6.38461C1 3.41587 3.41587 1 6.38462 1C8.34075 1 10.0565 2.04868 11 3.61418C11.9435 2.04868 13.6593 1 15.6154 1C18.5841 1 21 3.41587 21 6.38461C21 12.1779 11.7782 17.5925 11.3846 17.8179L11 18.0433Z" stroke="#21201F" stroke-width="2"></path>
						</svg>
					</div>
				</div>
			</div> -->
			<div class="RestaurantPageNewHeaderTopBar_headBtn" @click="$router.push('/cart')">
			  <span class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger" v-if="cartItems > 0">
			    {{ cartItems }}
			    <span class="visually-hidden">empty cart</span>
  </span>

			<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.887 20.6L7.03 7h9.942l1.142 13.6H5.887z" stroke="#fff" stroke-width="2"/><path d="M9 9V5.4v0a3 3 0 013-3v0a3 3 0 013 3V9" stroke="#fff" stroke-width="2"/></svg>
			</div>
		</div>
	</div>
  <div class="layout_body__lgFCb home-page_layout__xFNxW">
  <nav class="navigation_navigation__V40zi">
    <a class="navigation_user__jYtrx" @click="$router.push('bonus')">
      <div class="navigation_avatar__k8q_O"><img alt="lexprod" srcset="https://ubelluchi.ru/ava.jpg 1x, https://ubelluchi.ru/ava.jpg 2x" src="https://ubelluchi.ru/ava.jpg" width="36" height="36" decoding="async" data-nimg="1" loading="lazy" style="color: transparent;"></div>
      <div class="navigation_detail__SbPTg">
        <div class="navigation_name_box___VJcS">
          <div class="navigation_name__rmOY_ username" style="color:white;">{{userFirstName}}</div>
          <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m7.5 5 5 5-5 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </div>
        <div class="navigation_badge__xEFxj" style="color:white;">Мои бонусы</div>
      </div>
    </a>
    <a href="https://t.me/ubelluchi" class="button_button__FUDeW button_default__AaAY9 navigation_badge_btn__2hnSg">
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z" fill="#007aff"></path>
        <path d="M3.507 10.426c1.174-.642 2.485-1.178 3.71-1.716a194.018 194.018 0 0 1 6.362-2.556c.415-.137 1.162-.272 1.236.34-.04.865-.205 1.726-.319 2.586-.287 1.892-.619 3.778-.943 5.663-.111.629-.905.954-1.412.552-1.22-.818-2.449-1.627-3.653-2.463-.394-.398-.028-.969.324-1.253 1.004-.982 2.07-1.816 3.022-2.85.257-.615-.502-.096-.752.063-1.376.94-2.717 1.938-4.167 2.764-.74.404-1.604.059-2.344-.167-.664-.273-1.637-.547-1.064-.963Z" fill="#fff"></path>
      </svg>
      <div style="color: white;">@ubelluchi
        <div class="navigation_desc__EqY6B" style="color: white;">наш Telegram-канал</div>
      </div>
    </a>
  </nav>
  </div>
</template>

<script>
	import debounce from 'lodash/debounce';
	export default {
		name: 'header-component',
		props: {
			itemToShow: {
				type: Object,
				required: true,
			},
			cartItems: {
				type: Number,
				default: 0,
				required: false
			}
		},
		data() {
			return {
				scroll: false,
			}
		},
		methods: {
			handleScroll(event) {
			  // Any code to be executed when the window is scrolled
			  this.isUserScrolling = (window.scrollY > 0);
			  if(window.scrollY > 100) {
			  	this.scroll = true;
			  } else {
			  	this.scroll = false;
			  }
			  console.log('calling handleScroll');
			},
			getClass(property){
				if(this.homePage()) {
					return 'RestaurantPageNewHeaderTopBar_floating';
				} else {
					return {
					  'RestaurantPageNewHeaderTopBar_floating': this.scroll,
					  '': !scroll
					}
				}
			},
			homePage() {
				if(this.$route.path == "/" || this.$route.path == "/home" ) {
				  return true
				} else {
				  return false
				}
			}
		},
		mounted() {
			this.handleDebouncedScroll = debounce(this.handleScroll, 100);
			window.addEventListener('scroll', this.handleDebouncedScroll);
		},

		beforeDestroy() {
			// I switched the example from `destroyed` to `beforeDestroy`
			// to exercise your mind a bit. This lifecycle method works too.
			window.removeEventListener('scroll', this.handleDebouncedScroll);
		}
	}
</script>

<style scoped>

.home-page_layout__xFNxW {
  padding-top: 0;
  background: var(--background-secondary);
}
.layout_body__lgFCb {
  padding: 16px;
  width: 100%;
  min-height: calc(var(--vh)*100 + 1px);
  height: auto;
}
.navigation_navigation__V40zi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  -webkit-user-select: none;
  user-select: none;
}
.navigation_navigation__V40zi .navigation_user__jYtrx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_avatar__k8q_O {
  border-radius: 999999px;
  border: 1px solid var(--background);
  width: 42px;
  height: 42px;
  overflow: hidden;
  background: var(--background);
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_avatar__k8q_O img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_detail__SbPTg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_detail__SbPTg .navigation_name_box___VJcS {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_detail__SbPTg .navigation_name__rmOY_ {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 40vw;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.navigation_navigation__V40zi .navigation_badge_btn__2hnSg[data-v-d7373a36] {
  background: #242424 !important;
  padding: 4px 11px 4px 8px;
  border-radius: 20px;
  gap: 5px;
  display: flex;
  box-shadow: none;
  align-items: center;
  flex-direction: row;
  border: none;
  color: var(--text-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_detail__SbPTg .navigation_name_box___VJcS svg {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
}
.navigation_navigation__V40zi .navigation_user__jYtrx .navigation_detail__SbPTg .navigation_badge__xEFxj {
  display: flex;
  align-items: center;
  padding: 1px 3px;
  border-radius: 5px;
  color: var(--background);
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  background: var(--text-color);
}
.navigation_navigation__V40zi .navigation_badge_btn__2hnSg {
  background: var(--background);
  padding: 4px 10px 4px 4px;
  border-radius: 20px;
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--text-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.navigation_navigation__V40zi .navigation_badge_btn__2hnSg svg {
  width: 26px;
  height: 26px;
  color: var(--accent);
}
.navigation_navigation__V40zi .navigation_badge_btn__2hnSg .navigation_desc__EqY6B {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: var(--hint-color);
}
</style>