import { defineStore } from 'pinia'
const fakeStoreUrl = 'https://ubelluchi.ru/api'
export const useTelegramStore = defineStore({
    id: 'telegram',
    state: () => ({
        user: {
            id: null,
        },
        hasError: false,
        checkCode: false,
        client: {
            status: "wait",
            bonuses: 0,
            user: "wait"
        },
    }),
    getters: {
        tguser: (state) => state.user,
    },
    actions: {
        setStore(data) {
            this.user = data
        },
        async getUser(id) {
            const res = await fetch(`${fakeStoreUrl}/getUser/${id}`)
            const data = await res.json()
            return this.client = data;
        }
    },
})