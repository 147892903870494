<script setup>
import router from "@/router/router";
import {useCartStore} from "@/store/cart";
import {useTelegramStore} from "@/store/telegram";
import {computed, watch} from "vue";
import axios from "axios";
const cartStore = useCartStore();
const tgStore = useTelegramStore();
const userTgId = computed(() => tgStore.user.id);
tgStore.getUser(userTgId.value);
let tg = window.Telegram.WebApp;
var smsresponse = computed(() => tgStore.checkCode);
var hasErrorMsg = computed(() => tgStore.hasError), errorMsg = "";
tg.BackButton.hide();
tg.MainButton.text = "Вернуться в каталог";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  router.push('/');
});
watch(
    () => hasErrorMsg,
    () => {
      errorMsg = "";
    },
)
const phoneMask = (node) => {
  // We add a check to add the cast only to range inputs
  if (node.props.id !== 'phone') return

  node.hook.input((value, next) => mask(value))
}

function mask(value = "-") {
  var formattedInputValue = "";
  var t = value.replace(/\D/g, '');

  if (["7", "8", "9"].indexOf(t[0]) > -1) {
    if (t[0] == "9") t = "9" + t;
    var firstSymbols = (t[0] == "8") ? "+7" : "+7";
    formattedInputValue = value = firstSymbols + "";
    if (t.length > 1) {
      formattedInputValue += '-' + t.substring(1, 4);
    }
    if (t.length >= 5) {
      formattedInputValue += '-' + t.substring(4, 7);
    }
    if (t.length >= 8) {
      formattedInputValue += '-' + t.substring(7, 9);
    }
    if (t.length >= 10) {
      formattedInputValue += '-' + t.substring(9, 11);
    }
  } else {
    formattedInputValue = '' + t.substring(0, 16);
  }
  return formattedInputValue;
}

const createOrder = async (fields) => {
  await new Promise((r) => setTimeout(r, 1000))
  var form = {
    form: fields,
  };
  tg.MainButton.isVisible = false;
  axios
      .post(`https://ubelluchi.ru/api/checkUserTg`, {form: form.form})
      .then((response) => {
        if(response.data.status === true) {
          tgStore.hasError = false;
          tgStore.checkCode = true;
          if(response.data.auth === true) {
            tgStore.client.user = 'wait';
            tgStore.getUser(userTgId.value);
          }
        } else {
          tgStore.hasError = true;
          errorMsg = response.data.error;
        }
      })
      .catch(error => {
        tg.MainButton.isVisible = true;
        alert(error);
      });

  // alert(JSON.stringify(form.cart[0].title))
}

</script>

<template>
  <div class="thank-p" v-if="tgStore.client.user === 'wait'">Получаем данные...</div>

<div class="container" v-if="tgStore.client.user == 'notexists'">
  <h2>Регистрация в бонусной программе</h2>
  <FormKit v-if="tgStore.client.user == 'notexists'" type="form" ref="myForm" @submit="createOrder" :plugins="[phoneMask]" #default="{ value }" :actions="false">
    <FormKit
        type="hidden"
        name="user_id"
        id="user_id"
        :value="userTgId"
    />
    <FormKit
        type="hidden"
        name="bday"
        id="bday"
        v-model="formatdate"
    />
    <FormKit
        type="text"
        name="given_name"
        id="given_name"
        value=""
        validation="required"
        label="Ваше имя"
    />

    <FormKit
        type="text"
        inputmode="numeric"
        pattern="+7-[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
        name="phone"
        id="phone"
        value=""
        validation="required|length:16,16"
        label="Телефон"
    />
    <label class="formkit-label" for="phone">Дата рождения</label>
    <VueDatePicker
        @update:model-value="selectDate"
        placeholder="Укажите дату рождения"
        locale="ru"
        required
        v-model="date"
        dark
        :enable-time-picker="false"
        auto-apply
        :format="format"
    /><br>
    <FormKit
        type="text"
        v-if="smsresponse"
        inputmode="numeric"
        pattern="[0-9-]*"
        name="token"
        id="token"
        value=""
        validation="required|length:4,4"
        label="Код подтверждения"
    />
    <br>
    <p style="color: red;font-weight: bold;font-size: 13px;" v-if="hasErrorMsg">{{errorMsg}}</p>
    <FormKit type="submit" > <span v-if="!tgStore.checkCode">Зарегистрироваться</span><span v-else="tgStore.checkCode">Подтвердить</span> </FormKit>
  </FormKit>
</div>
  <div class="container" v-if="tgStore.client.user === 'exists'">
    <p>На вашем бонусном счете: <span>{{tgStore.client.bonuses}} руб.</span></p>
    <div id="qr-code" v-html="tgStore.client.data"></div>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css'
import axios from "axios";

export default {
  components: { VueDatePicker },
  data() {
    return {
      date: null,
      formatdate: null,

    };
  },
  methods: {
    selectDate(date) {
      if(date) {
        this.formatdate = this.format(date);
      }
    },
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      var monthText = "";
      if(month.toString().length === 1) {
        monthText = '0'+month;
      } else {
        monthText = month;
      }
      return `${day}.${monthText}.${year}`;
    }
  }
}
</script>
<style scoped>
.thank-p {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}
</style>