<script setup>
import { useCartStore } from '@/store/cart'
import { useProductStore } from '@/store/products'
const productStore = useProductStore();
const cartStore = useCartStore();
</script>
<template>
	<div class="MobileMenuItem_root MobileMenuItem_medium">
		<div class="UiKitProductCard_root UiKitProductCard_m MobileMenuItem_card">
			<div class="UiKitProductCard_pictureWrapper" @click="$router.push(`/category/item/${product.id}`)">
				<div class="SmartImage_root UiKitProductCard_picture SmartImage_hideCover">
					<div class="SmartImage_cover"></div>
					<img :src="product.pictures" class="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" loading="lazy">
				</div>
				<div class="UiKitProductCard_tokensWrapper"></div>
			</div>
			<div class="UiKitProductCard_infoWrapper"><span class="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitText_root UiKitText_Body2 UiKitText_Medium UiKitText_Text">{{ product.title }}</span>
				<div class="UiKitProductCardTitle_title UiKitProductCard_name">{{ product.price }} ₽<span v-if="product.pricecount"> / {{ product.pricecount }} {{product.pricefor}}</span></div>
        <div class="UiKitProductCard_name UiKitText_root UiKitText_Body2Tight UiKitText_Medium UiKitText_TextMinor UiKitText_withLineClamp" style="-webkit-line-clamp: 1; max-height: 17px;">{{ product.quantitycount }} {{ product.quantitytype }} <span v-if="product.pricecount && product.pricefor != 'шт.'">(ср. вес)</span>

        </div>
			</div>
			<div class="UiKitCounter_root UiKitProductCard_amountSelect UiKitCounter_xs UiKitCounter_floating">
				<button v-if="cartStore.getItemCount(product.id) <= 0" class="UiKitCounter_button UiKitCounter_onlyIncrement" @click="cartStore.add(product)">
					<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root UiKitCounter_icon">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M12 6a1 1 0 0 0-1 1v4H7a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V7a1 1 0 0 0-1-1Z" fill="currentColor"></path>
					</svg><span class="UiKitCounter_incrementText">Добавить</span>
				</button>
				<div class="Counter_root" v-if="cartStore.getItemCount(product.id) > 0">
					<button class="UiKitCounter_button UiKitCounter_decrement" @click="cartStore.remove(product)">
						<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root UiKitCounter_icon">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M6 12a1 1 0 0 0 1 1h10a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" fill="currentColor"></path>
						</svg>
					</button><span class="UiKitCounter_valueContainer"><b>{{ cartStore.getItemCount(product.id) }}</b></span>
					<button class="UiKitCounter_button UiKitCounter_increment" @click="cartStore.add(product)">
						<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root UiKitCounter_icon">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M12 6a1 1 0 0 0-1 1v4H7a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V7a1 1 0 0 0-1-1Z" fill="currentColor"></path>
						</svg>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['product', 'catSlug']
	}
</script>