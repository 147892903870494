import { defineStore } from 'pinia'
import { useProductStore } from './products'
import { CART_STORAGE } from '@/composables/usePersistCart'


export const useCartStore = defineStore({
  id: 'cart',

  state: () => ({
    contents: JSON.parse(localStorage.getItem(CART_STORAGE)) ?? {},
    isDelivery: true,
    initLoad: false,
    deliveryMin: 100,
    pickupMin: 100
  }),

  getters: {
    count(state) {
      return Object.keys(this.contents).reduce((acc, id) => {
        return acc + this.contents[id].quantity
      }, 0)
    },
    total(state) {
      const products = useProductStore()
      return Object.keys(this.contents).reduce((acc, id) => {
        var price = this.contents[id].price;
        return acc + price * this.contents[id].quantity
      }, 0)
    },
    formattedCart() {
      const products = useProductStore()
      return Object.keys(this.contents).map((productId) => {
        const purchase = this.contents[productId]
        return {
          id: purchase.productId,
          pictures: purchase.pictures,
          title: purchase.title,
          code: purchase.code,
          quantity: purchase.quantity,
          cost: purchase.quantity * purchase.price,
          pickupcost: purchase.quantity * purchase.priceSelfdelivery,
          price: purchase.price,
        }
      })
    },
  },

  actions: {
    getItemCount(productId) {
      if (this.contents[productId]) {
        return this.contents[productId].quantity;
      } else {
        return 0;
      }
    },
    add(product) {
      var productId = product.id; 
      var productPrice = parseInt(product.price);
      var productPricePickup = parseInt(product.priceSelfdelivery);
      if (this.contents[productId]) {
        this.contents[productId].quantity += 1
      }
      else {
        this.contents[productId] = {
          productId,
          title: product.title,
          code: product.code,
          pictures: product.pictures,
          priceSelfdelivery: productPricePickup,
          price: productPrice,
          quantity: 1,
        }
      }
    },
    clearCart() {
      Object.keys(this.contents).forEach((k) => delete this.contents[k]);
    },
    remove(product) {
      var productId = product.id; 
      if (!this.contents[productId])
        return
      this.contents[productId].quantity -= 1

      if (this.contents[productId].quantity === 0)
        delete this.contents[productId]
    },
  },
})
